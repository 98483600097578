import React from "react";
import './footer.css'

function Footer() {
  return(
    <div className="footer">
    <p style={{alignContent: "center", margin: 0, padding: 0}}>© 2022 TechThanks</p>
    </div>
  )
}

export default Footer