import Hero from "../components/home/Hero";
import Main from "../components/home/Main";

export default function Home() {
  return (
    <>
      <Hero />
      <Main />
    </>
  );
}
